<template>
  <vx-card title="Promotion Budget Approval">
    <!-- main page -->
    <div v-if="formDetail">
      <formBudget
        :selectedBudget="selectedBudget"
        :disabledFrom="disabledFrom"
        :sourceTab="sourceTab"
        v-on:backTo="backToSourceTab"
      />
    </div>
    <!-- <div class="con-tab-ejemplo"  v-else>
      <datatable :progress="'pending'" v-on:clickView="clickView"></datatable>
    </div> -->
    <vs-tabs v-model="tab" v-else>
      <vs-tab label="Pending">
        <div class="con-tab-ejemplo">
          <datatable :progress="'pending'" v-on:clickView="clickView"></datatable>
        </div>
      </vs-tab>
      <vs-tab label="Approved">
        <div class="con-tab-ejemplo">
          <datatable
            :progress="'approved'"
            :progressRestriction="true"
            v-on:clickView="clickView"
          ></datatable>
        </div>
      </vs-tab>
      <vs-tab label="Rejected">
        <div class="con-tab-ejemplo">
          <datatable
            :progress="'rejected'"
            :progressRestriction="true"
            v-on:clickEdit="clickEdit"
          ></datatable>
        </div>
      </vs-tab>
      <!-- <vs-tab label="Canceled">
        <div class="con-tab-ejemplo">
          <datatable
            :isCancel="1"
            :progress="'canceled'"
            v-on:clickView="clickView"
          ></datatable>
        </div>
      </vs-tab> -->
      <div v-if="false">
        <vs-tab label="Released">
          <div class="con-tab-ejemplo">
            <datatable :progress="'released'" :progressRestriction="true"></datatable>
          </div>
        </vs-tab>
      </div>
    </vs-tabs>
  </vx-card>
</template>

<script>
import datatable from "./datatable.vue";
import formBudget from "../form/form.vue";

export default {
  components: {
    datatable,
    formBudget,
  },
  props: [],
  data() {
    return {
      tab: 0,
      formDetail: false,
      selectedBudget: {},
      disabledFrom: true,
      sourceTab: 0,
    };
  },
  watch: {
    //
  },
  mounted() {},
  computed: {},
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    changeSubTab() {},
    backToSourceTab() {
      this.formDetail = false;
      this.tab = this.sourceTab;
      this.selectedBudget = {};
    },

    clickEdit(budget, sourceTab) {
      this.$emit("selectBudget", budget);
      this.$emit("redirectTab", 0, false, sourceTab);
    },
    clickView(budget, sourceTab) {
      console.log("sourceTab2", sourceTab);
      this.disabledFrom = true;
      this.sourceTab = sourceTab;
      this.selectedBudget = budget;
      this.formDetail = true;
    },
  },
};
</script>

<style>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
